
// @ts-nocheck
import locale_en_46js_f4af13b8 from "#nuxt-i18n/f4af13b8";
import locale_es_46js_596f212b from "#nuxt-i18n/596f212b";
import locale_fr_46js_e47fb9da from "#nuxt-i18n/e47fb9da";
import locale_de_46js_030cdc07 from "#nuxt-i18n/030cdc07";
import locale_pt_46js_1b7d157f from "#nuxt-i18n/1b7d157f";
import locale_it_46js_07061201 from "#nuxt-i18n/07061201";
import locale_nl_46js_7d0e34fb from "#nuxt-i18n/7d0e34fb";
import locale_pl_46js_4228b663 from "#nuxt-i18n/4228b663";
import locale_jp_46js_b3452ecc from "#nuxt-i18n/b3452ecc";
import locale_en_46ts_fbd3db07 from "#nuxt-i18n/fbd3db07";

export const localeCodes =  [
  "us",
  "uk",
  "au",
  "ca",
  "ie",
  "nz",
  "za",
  "es",
  "cl",
  "ar",
  "mx",
  "fr",
  "ca-fr",
  "be",
  "de",
  "ch",
  "ch-fr",
  "at",
  "pt",
  "br",
  "it",
  "nl",
  "be-nl",
  "pl",
  "hu",
  "cy",
  "gr",
  "jp",
  "en"
]

export const localeLoaders = {
  us: [
    {
      key: "locale_en_46js_f4af13b8",
      load: () => Promise.resolve(locale_en_46js_f4af13b8),
      cache: true
    }
  ],
  uk: [
    {
      key: "locale_en_46js_f4af13b8",
      load: () => Promise.resolve(locale_en_46js_f4af13b8),
      cache: true
    }
  ],
  au: [
    {
      key: "locale_en_46js_f4af13b8",
      load: () => Promise.resolve(locale_en_46js_f4af13b8),
      cache: true
    }
  ],
  ca: [
    {
      key: "locale_en_46js_f4af13b8",
      load: () => Promise.resolve(locale_en_46js_f4af13b8),
      cache: true
    }
  ],
  ie: [
    {
      key: "locale_en_46js_f4af13b8",
      load: () => Promise.resolve(locale_en_46js_f4af13b8),
      cache: true
    }
  ],
  nz: [
    {
      key: "locale_en_46js_f4af13b8",
      load: () => Promise.resolve(locale_en_46js_f4af13b8),
      cache: true
    }
  ],
  za: [
    {
      key: "locale_en_46js_f4af13b8",
      load: () => Promise.resolve(locale_en_46js_f4af13b8),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_46js_596f212b",
      load: () => Promise.resolve(locale_es_46js_596f212b),
      cache: true
    }
  ],
  cl: [
    {
      key: "locale_es_46js_596f212b",
      load: () => Promise.resolve(locale_es_46js_596f212b),
      cache: true
    }
  ],
  ar: [
    {
      key: "locale_es_46js_596f212b",
      load: () => Promise.resolve(locale_es_46js_596f212b),
      cache: true
    }
  ],
  mx: [
    {
      key: "locale_es_46js_596f212b",
      load: () => Promise.resolve(locale_es_46js_596f212b),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_46js_e47fb9da",
      load: () => Promise.resolve(locale_fr_46js_e47fb9da),
      cache: true
    },
    {
      key: "locale_fr_46js_e47fb9da",
      load: () => Promise.resolve(locale_fr_46js_e47fb9da),
      cache: true
    }
  ],
  "ca-fr": [
    {
      key: "locale_fr_46js_e47fb9da",
      load: () => Promise.resolve(locale_fr_46js_e47fb9da),
      cache: true
    }
  ],
  be: [
    {
      key: "locale_fr_46js_e47fb9da",
      load: () => Promise.resolve(locale_fr_46js_e47fb9da),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_46js_030cdc07",
      load: () => Promise.resolve(locale_de_46js_030cdc07),
      cache: true
    }
  ],
  ch: [
    {
      key: "locale_de_46js_030cdc07",
      load: () => Promise.resolve(locale_de_46js_030cdc07),
      cache: true
    }
  ],
  "ch-fr": [
    {
      key: "locale_fr_46js_e47fb9da",
      load: () => Promise.resolve(locale_fr_46js_e47fb9da),
      cache: true
    }
  ],
  at: [
    {
      key: "locale_de_46js_030cdc07",
      load: () => Promise.resolve(locale_de_46js_030cdc07),
      cache: true
    }
  ],
  pt: [
    {
      key: "locale_pt_46js_1b7d157f",
      load: () => Promise.resolve(locale_pt_46js_1b7d157f),
      cache: true
    }
  ],
  br: [
    {
      key: "locale_pt_46js_1b7d157f",
      load: () => Promise.resolve(locale_pt_46js_1b7d157f),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_46js_07061201",
      load: () => Promise.resolve(locale_it_46js_07061201),
      cache: true
    }
  ],
  nl: [
    {
      key: "locale_nl_46js_7d0e34fb",
      load: () => Promise.resolve(locale_nl_46js_7d0e34fb),
      cache: true
    }
  ],
  "be-nl": [
    {
      key: "locale_nl_46js_7d0e34fb",
      load: () => Promise.resolve(locale_nl_46js_7d0e34fb),
      cache: true
    }
  ],
  pl: [
    {
      key: "locale_pl_46js_4228b663",
      load: () => Promise.resolve(locale_pl_46js_4228b663),
      cache: true
    }
  ],
  hu: [
    {
      key: "locale_de_46js_030cdc07",
      load: () => Promise.resolve(locale_de_46js_030cdc07),
      cache: true
    }
  ],
  cy: [
    {
      key: "locale_es_46js_596f212b",
      load: () => Promise.resolve(locale_es_46js_596f212b),
      cache: true
    }
  ],
  gr: [
    {
      key: "locale_es_46js_596f212b",
      load: () => Promise.resolve(locale_es_46js_596f212b),
      cache: true
    }
  ],
  jp: [
    {
      key: "locale_jp_46js_b3452ecc",
      load: () => Promise.resolve(locale_jp_46js_b3452ecc),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_46ts_fbd3db07",
      load: () => Promise.resolve(locale_en_46ts_fbd3db07),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "us",
      storyblok: "en",
      iso: "en-US",
      lang: "English",
      name: "United States",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
          cache: undefined
        }
      ]
    },
    {
      code: "uk",
      storyblok: "en",
      iso: "en-GB",
      lang: "English",
      name: "United Kingdom",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
          cache: undefined
        }
      ]
    },
    {
      code: "au",
      storyblok: "en",
      iso: "en-AU",
      lang: "English",
      name: "Australia",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
          cache: undefined
        }
      ]
    },
    {
      code: "ca",
      storyblok: "en",
      iso: "en-CA",
      lang: "English",
      name: "Canada",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
          cache: undefined
        }
      ]
    },
    {
      code: "ie",
      storyblok: "en",
      iso: "en-IE",
      lang: "English",
      name: "Ireland",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
          cache: undefined
        }
      ]
    },
    {
      code: "nz",
      storyblok: "en",
      iso: "en-NZ",
      lang: "English",
      name: "New Zealand",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
          cache: undefined
        }
      ]
    },
    {
      code: "za",
      storyblok: "en",
      iso: "en-ZA",
      lang: "English",
      name: "South Africa",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
          cache: undefined
        }
      ]
    },
    {
      code: "es",
      storyblok: "es",
      iso: "es-ES",
      lang: "Spanish",
      name: "España",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
          cache: undefined
        }
      ]
    },
    {
      code: "cl",
      storyblok: "es",
      iso: "es-CL",
      lang: "Spanish",
      name: "Chile",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
          cache: undefined
        }
      ]
    },
    {
      code: "ar",
      storyblok: "es",
      iso: "es-AR",
      lang: "Spanish",
      name: "Argentina",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
          cache: undefined
        }
      ]
    },
    {
      code: "mx",
      storyblok: "es",
      iso: "es-MX",
      lang: "Spanish",
      name: "México",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
          cache: undefined
        }
      ]
    },
    {
      code: "fr",
      storyblok: "fr",
      iso: "fr-FR",
      lang: "French",
      name: "France",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
          cache: undefined
        },
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
          cache: undefined
        }
      ]
    },
    {
      code: "ca-fr",
      storyblok: "fr",
      iso: "fr-CA",
      lang: "French",
      name: "Canada",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
          cache: undefined
        }
      ]
    },
    {
      code: "be",
      storyblok: "fr",
      iso: "fr-BE",
      lang: "French",
      name: "Belgique",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
          cache: undefined
        }
      ]
    },
    {
      code: "de",
      storyblok: "de",
      iso: "de-DE",
      lang: "German",
      name: "Deutschland",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
          cache: undefined
        }
      ]
    },
    {
      code: "ch",
      storyblok: "de",
      iso: "de-CH",
      lang: "German",
      name: "Schweiz",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
          cache: undefined
        }
      ]
    },
    {
      code: "ch-fr",
      storyblok: "fr",
      iso: "fr-CH",
      lang: "French",
      name: "Suisse",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
          cache: undefined
        }
      ]
    },
    {
      code: "at",
      storyblok: "de",
      iso: "de-AT",
      lang: "German",
      name: "Österreich",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
          cache: undefined
        }
      ]
    },
    {
      code: "pt",
      storyblok: "pt",
      iso: "pt-PT",
      lang: "Portuguese",
      name: "Portugal",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/pt.js",
          cache: undefined
        }
      ]
    },
    {
      code: "br",
      storyblok: "pt",
      iso: "pt-BR",
      lang: "Portuguese",
      name: "Brasil",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/pt.js",
          cache: undefined
        }
      ]
    },
    {
      code: "it",
      storyblok: "it",
      iso: "it-IT",
      lang: "Italian",
      name: "Italia",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/it.js",
          cache: undefined
        }
      ]
    },
    {
      code: "nl",
      storyblok: "nl",
      iso: "nl-NL",
      lang: "Dutch",
      name: "Nederland",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/nl.js",
          cache: undefined
        }
      ]
    },
    {
      code: "be-nl",
      storyblok: "nl",
      iso: "nl-BE",
      lang: "Dutch",
      name: "België",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/nl.js",
          cache: undefined
        }
      ]
    },
    {
      code: "pl",
      storyblok: "pl",
      iso: "pl-PL",
      lang: "Polish",
      name: "Polska",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/pl.js",
          cache: undefined
        }
      ]
    },
    {
      code: "hu",
      storyblok: "de",
      iso: "de-HU",
      lang: "German",
      name: "Hungary",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
          cache: undefined
        }
      ]
    },
    {
      code: "cy",
      storyblok: "es",
      iso: "es-CY",
      lang: "Spanish",
      name: "Cyprus",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
          cache: undefined
        }
      ]
    },
    {
      code: "gr",
      storyblok: "es",
      iso: "es-GR",
      lang: "Spanish",
      name: "Greece",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
          cache: undefined
        }
      ]
    },
    {
      code: "jp",
      storyblok: "jp",
      iso: "jp-JP",
      lang: "Japanese",
      name: "日本",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/jp.js",
          cache: undefined
        }
      ]
    },
    {
      code: "en",
      iso: "en-GB",
      lang: "English",
      name: "United Kingdom",
      files: [
        {
          path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/i18n/lang/en.ts",
          cache: undefined
        }
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "lang/",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {
    index: false
  },
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  fallbackLocale: "uk",
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "us",
    storyblok: "en",
    iso: "en-US",
    lang: "English",
    name: "United States",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "uk",
    storyblok: "en",
    iso: "en-GB",
    lang: "English",
    name: "United Kingdom",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "au",
    storyblok: "en",
    iso: "en-AU",
    lang: "English",
    name: "Australia",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "ca",
    storyblok: "en",
    iso: "en-CA",
    lang: "English",
    name: "Canada",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "ie",
    storyblok: "en",
    iso: "en-IE",
    lang: "English",
    name: "Ireland",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "nz",
    storyblok: "en",
    iso: "en-NZ",
    lang: "English",
    name: "New Zealand",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "za",
    storyblok: "en",
    iso: "en-ZA",
    lang: "English",
    name: "South Africa",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    storyblok: "es",
    iso: "es-ES",
    lang: "Spanish",
    name: "España",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
        cache: undefined
      }
    ]
  },
  {
    code: "cl",
    storyblok: "es",
    iso: "es-CL",
    lang: "Spanish",
    name: "Chile",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
        cache: undefined
      }
    ]
  },
  {
    code: "ar",
    storyblok: "es",
    iso: "es-AR",
    lang: "Spanish",
    name: "Argentina",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
        cache: undefined
      }
    ]
  },
  {
    code: "mx",
    storyblok: "es",
    iso: "es-MX",
    lang: "Spanish",
    name: "México",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    storyblok: "fr",
    iso: "fr-FR",
    lang: "French",
    name: "France",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
        cache: undefined
      },
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
        cache: undefined
      }
    ]
  },
  {
    code: "ca-fr",
    storyblok: "fr",
    iso: "fr-CA",
    lang: "French",
    name: "Canada",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
        cache: undefined
      }
    ]
  },
  {
    code: "be",
    storyblok: "fr",
    iso: "fr-BE",
    lang: "French",
    name: "Belgique",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    storyblok: "de",
    iso: "de-DE",
    lang: "German",
    name: "Deutschland",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
        cache: undefined
      }
    ]
  },
  {
    code: "ch",
    storyblok: "de",
    iso: "de-CH",
    lang: "German",
    name: "Schweiz",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
        cache: undefined
      }
    ]
  },
  {
    code: "ch-fr",
    storyblok: "fr",
    iso: "fr-CH",
    lang: "French",
    name: "Suisse",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/fr.js",
        cache: undefined
      }
    ]
  },
  {
    code: "at",
    storyblok: "de",
    iso: "de-AT",
    lang: "German",
    name: "Österreich",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
        cache: undefined
      }
    ]
  },
  {
    code: "pt",
    storyblok: "pt",
    iso: "pt-PT",
    lang: "Portuguese",
    name: "Portugal",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/pt.js",
        cache: undefined
      }
    ]
  },
  {
    code: "br",
    storyblok: "pt",
    iso: "pt-BR",
    lang: "Portuguese",
    name: "Brasil",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/pt.js",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    storyblok: "it",
    iso: "it-IT",
    lang: "Italian",
    name: "Italia",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/it.js",
        cache: undefined
      }
    ]
  },
  {
    code: "nl",
    storyblok: "nl",
    iso: "nl-NL",
    lang: "Dutch",
    name: "Nederland",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/nl.js",
        cache: undefined
      }
    ]
  },
  {
    code: "be-nl",
    storyblok: "nl",
    iso: "nl-BE",
    lang: "Dutch",
    name: "België",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/nl.js",
        cache: undefined
      }
    ]
  },
  {
    code: "pl",
    storyblok: "pl",
    iso: "pl-PL",
    lang: "Polish",
    name: "Polska",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/pl.js",
        cache: undefined
      }
    ]
  },
  {
    code: "hu",
    storyblok: "de",
    iso: "de-HU",
    lang: "German",
    name: "Hungary",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/de.js",
        cache: undefined
      }
    ]
  },
  {
    code: "cy",
    storyblok: "es",
    iso: "es-CY",
    lang: "Spanish",
    name: "Cyprus",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
        cache: undefined
      }
    ]
  },
  {
    code: "gr",
    storyblok: "es",
    iso: "es-GR",
    lang: "Spanish",
    name: "Greece",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/es.js",
        cache: undefined
      }
    ]
  },
  {
    code: "jp",
    storyblok: "jp",
    iso: "jp-JP",
    lang: "Japanese",
    name: "日本",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/i18n/lang/jp.js",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    iso: "en-GB",
    lang: "English",
    name: "United Kingdom",
    files: [
      {
        path: "/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/i18n/lang/en.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/